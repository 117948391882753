import { ChildDataProps } from '@apollo/client/react/hoc'
import React, { useContext } from 'react'
import Cookies from 'js-cookie'

import { ReportViewer } from 'suf_storybook'
import { OverlayContext } from './OverlayContext'
import { loadMarketoForm } from '../lib'

// eslint-disable-next-line import/no-extraneous-dependencies

export type MarketingTagProps = {
  slug: string
}

export type MarketingProps = {
  contentAccessType: MarketingTagProps
}

export type MarketoResult = {
  sfWebValidation: boolean
  webuserId: string
}

export type InputProps = {
  abstract?: string
  dbDocId: number
  fileExtension: string
  reportURL?: string
  marketing: MarketingProps
}

type ChildProps = ChildDataProps<InputProps, Response>

export default (props: ChildProps) => {
  const { setMarketoFormId, toggleOverlay } = useContext(OverlayContext)
  const {
    abstract,
    fileExtension,
    reportURL,
    marketing: {
      contentAccessType: { slug: contentType }
    }
  } = props
  const anonymousAccess = contentType === 'anonymous'
  const fileSource = reportURL
  let sfWebValidation = false

  const expireDate = new Date()
  expireDate.setDate(expireDate.getDate() + 365)
  const emailValidationField = 'sFWebAccessReportValidation=true' // to check if user is accessing report using url from his email
  const emailUrl = window.location.href
  if (emailUrl.indexOf(emailValidationField) > -1) {
    sfWebValidation = true
    Cookies.set('marketo', 'yes', { expires: expireDate })
  }
  const marketoFormId = '4337'
  const reportType = fileExtension

  const allowAccess =
    sfWebValidation || anonymousAccess || Cookies.get('marketo') === 'yes'

  const accessReportHandler = (
    setMarketoFormId: (item: any) => void,
    toggleOverlay: (item: any) => void
  ) => {
    loadMarketoForm(marketoFormId!, toggleOverlay, setMarketoFormId)
  }

  return (
    <div>
      {fileSource && (
        <ReportViewer
          abstract={abstract}
          allowAccess={allowAccess}
          type={reportType}
          src={fileSource}
          handleAcessReport={() =>
            accessReportHandler(setMarketoFormId, toggleOverlay)
          }
          handleDownloadReport={() => {}}
        />
      )}
    </div>
  )
}
