import React from 'react'
import { Router } from '@reach/router'
import RAC from '../../templates/RAC'
import DynamicNotFoundPage from '../dynamicNotFound'

export default () => (
  <Router>
    <RAC path="/structured-finance/*" />
    <RAC path="/sovereigns/*" />
    <RAC path="/corporate-finance/*" />
    <RAC path="/autos/*" />
    <RAC path="/aviation/*" />
    <RAC path="/banks/*" />
    <RAC path="/economics/*" />
    <RAC path="/energy-natural-resources/*" />
    <RAC path="/fund-asset-managers/*" />
    <RAC path="/gaming-lodging-leisure/*" />
    <RAC path="/healthcare-pharma/*" />
    <RAC path="/industrials-transportation/*" />
    <RAC path="/infrastructure-project-finance/*" />
    <RAC path="/insurance/*" />
    <RAC path="/international-public-finance/*" />
    <RAC path="/islamic-finance/*" />
    <RAC path="/metals-mining/*" />
    <RAC path="/non-bank-financial-institutions/*" />
    <RAC path="/real-estate-homebuilding/*" />
    <RAC path="/regulation/*" />
    <RAC path="/retail-consumer/*" />
    <RAC path="/technology-media-telecom/*" />
    <RAC path="/uncategorized/*" />
    <RAC path="/us-public-finance/*" />
    <RAC path="/utilities-power/*" />
    <DynamicNotFoundPage default />
  </Router>
)
