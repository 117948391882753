import React, { ComponentType, useEffect, useState } from 'react'
import { gql } from '@apollo/client'
import { ChildDataProps } from '@apollo/client/react/hoc'

import {
  LanguageFilter,
  InsightsLinkProps,
  InsightsResults
} from 'suf_storybook'

import { fetchGqlData } from '../lib'

export enum Subtitle {
  'insights/commentary' = 'Commentary',
  'insights/esg-rating-report' = 'ESG Ratings Action',
  'insights/sustainable-insight' = 'Sustainable Insight',
  'insights/esg-rating' = 'ESG Rating',
  'insights/second-party-opinion' = 'Second-Party Opinion'
}

export type InsightsResultsWrapperProps = {
  items: any
  language?: string
  renderLink: ComponentType<InsightsLinkProps>
  reportType?: string
}

type RAC = {
  abstract: string
  marketing: {
    reportType: {
      slug: string
    }
  }
  publishedDate: string
  reportType: string
  slug: string
  title: string
  languages: {
    title: string
  }
}

type Response = {
  getSFInsights: {
    rows: RAC[]
  }
}
type Variables = {
  language?: string
  sector?: string
  reportType?: string
  limit?: number
  languages: {
    title: string
  }
}
type ChildProps = ChildDataProps<
  InsightsResultsWrapperProps,
  Response,
  Variables
>

const hasRAC = gql`
  query SFInsights(
    $language: String
    $reportType: String
    $sector: String
    $limit: Int
  ) {
    getSFInsights(
      language: $language
      reportType: $reportType
      sector: $sector
      limit: $limit
    ) {
      rows {
        abstract
        marketing {
          language {
            slug
            name
          }
          reportType {
            slug
          }
        }
        publishedDate
        reportType
        slug
        title
      }
    }
  }
`

const getAbstract = (originalAbstract: string) => {
  if (originalAbstract.length <= 300) {
    return originalAbstract
  }
  const abstract = originalAbstract.slice(0, 300)
  return abstract.endsWith('.') ? abstract : `${abstract}...`
}

const parseResearchData = (research: RAC[]) => {
  return research.map((sfInsight: RAC) => {
    const {
      marketing: { reportType }
    } = sfInsight
    return {
      ...sfInsight,
      abstract: getAbstract(sfInsight.abstract),
      slug: `/${sfInsight.slug}`,
      articleType: {
        subTitle: Subtitle[reportType.slug]
      }
    }
  })
}

const wrapper = ({
  language = '',
  items,
  renderLink,
  reportType = '',
  languages
}: ChildProps) => {
  const lFilter = ['en']
  const [langFilter, setLangFilter] = useState([...lFilter])
  const [isDefault, setDefault] = useState(true)
  const handleClick = (e: any) => {
    const el = e.target.value
    const index = langFilter.indexOf(el)
    const temp = [...langFilter]
    if (index > -1) {
      temp.splice(index, 1)
      setLangFilter(() => [...temp])
      if (el === 'en') setDefault((isDefault) => !isDefault)
    } else {
      setLangFilter((langFilter) => [...langFilter, e.target.value])
      if (el === 'en') setDefault((isDefault) => !isDefault)
    }
  }

  const [sustainableInsights, setSustainableInsights] = useState<RAC[]>([])

  const variables: Variables = {
    language,
    reportType,
    languages
  }

  const updateResearchData = async () => {
    const fetchedData = fetchGqlData(hasRAC, variables)
    fetchedData.then((value: any) => {
      const updatedResearchData = value?.data?.getSFInsights?.rows
      setSustainableInsights(parseResearchData(updatedResearchData))
    })
  }
  useEffect(() => {
    updateResearchData()
  }, [])

  const allInsights = [...items, ...sustainableInsights]
    .filter((e) => {
      if (e.languages) {
        return langFilter.includes(e.languages[0].slug)
      }
      if (e.marketing) {
        if (e.marketing.language) {
          return langFilter.includes(e.marketing.language.slug)
        }
        return false
      }
      return false
    })
    .sort(
      (a, b) =>
        new Date(b.publishedDate).getTime() -
        new Date(a.publishedDate).getTime()
    )
  return (
    <>
      <LanguageFilter handleClick={handleClick} isDefault={isDefault} />
      <InsightsResults items={allInsights} renderLink={renderLink} />
    </>
  )
}

export default wrapper
