/* eslint-disable import/extensions */
import _ from 'lodash'
import { ChildDataProps, graphql } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'
import { graphql as gatsbyGraphql, useStaticQuery } from 'gatsby'
import React, { ComponentType } from 'react'
import { globalHistory } from '@reach/router'

import {
  AdditonalDisclosuresRenderLinkProps,
  AdditionalDisclosures,
  FormatDate,
  Paragraph,
  ParagraphCollapsible,
  ParagraphProps,
  Paragraphs,
  RatingHeader,
  RatingHeaderProps,
  Social
} from 'suf_storybook'

import hasLoading from '../components/hocs/hasLoading'
import hasRuntimeQuery from '../components/hocs/hasRuntimeQuery'
import { compose, getKeyModifiedObject, updateDisplayLabel } from '../lib'
import Main from '../components/main'
import ReportViewer from '../components/reportViewerWrapper'
import SEO from '../components/seo'
import { Subtitle } from '../components/insightsResultsWrapper'

type InputProps = {
  slug: string
}
// TODO: create proper response types
type Response = { getSFResearchItem: RatingHeaderProps }
type Variables = { slug: string }
type ChildProps = ChildDataProps<InputProps, Response, Variables>

type Props = {
  '*': string
  uri: string
}

const staticQuery = gatsbyGraphql`
  query SiteAllContentfulCountryRAC {
    excludeLinks {
      excludeLinks
    }
  }
`

const hasRAC = graphql<InputProps, Response, Variables, ChildProps>(
  gql`
    query RAC($slug: String!) {
      getSFResearchItem(slug: $slug) {
        abstract
        displayLabels
        upstreamSystem
        primaryReport {
          items {
            reportId
            slug
            title
            linkToFitchRatingsRpt
          }
        }
        copyright
        dbDocId
        disclaimer
        disclaimerCopyright
        disclosure
        docType
        endorsementPolicy
        fileExtension
        marketing {
          contentAccessType {
            name
            slug
          }
          displayTitle
          language {
            name
            slug
          }
          openGraphTags {
            author
            title
            url
            type
            regions
            articleTag
            sectionTag
          }
          metadataTags {
            title
            description
          }
          reportType {
            slug
          }
        }
        noIndex
        origin
        paragraphs {
          name
          fieldID
          header
          subHeader
          showHeader
          headerDisplayType
          content
        }
        publishedDate
        reportURL
        title
        version
      }
    }
  `,
  {
    options: ({ slug }) => ({
      variables: { slug }
    })
  }
)

const wrapper = ({ data, linkifyExclude, path, slug }: ChildProps) => {
  const { getSFResearchItem } = data

  if (getSFResearchItem === null) {
    window.location.replace(`/404.html?slug=${path}`)
  }

  const { displayTitle } = getSFResearchItem.marketing
  const displayLabels = _.get(getSFResearchItem, 'displayLabels', {})

  /* Modified Object with case insensitive keys : To be used to check any displayLabel availability for any labels;
   To be passed to updateDisplayLabel along with label to compare
  */
  const displayLabelsCaseIS = getKeyModifiedObject(displayLabels, 'lower')

  /**
   * This will translate the AdditionalDisclosures sub section title
   * @param section
   */
  const translateAdditionalDisclosuresSubSectionTitle = (
    section: AdditonalDisclosuresRenderLinkProps
  ) => {
    const title = updateDisplayLabel(displayLabelsCaseIS, section.title)
    return {
      link: section.link,
      title
    }
  }

  const researchTitle = displayTitle || getSFResearchItem.title
  const reportUrl: string | undefined = _.get(getSFResearchItem, 'reportURL')
  const reportType = _.get(getSFResearchItem, 'marketing.reportType.slug', '')
  // Get the DPC url for the copy button
  const { marketing } = getSFResearchItem

  const leadParagraph = {
    ..._.find(getSFResearchItem.paragraphs, { fieldID: 1001 })
  }
  leadParagraph.showHeader = false
  const bodyParagraphs: ParagraphProps[] = _.filter(
    getSFResearchItem.paragraphs,
    ({ fieldID }) => fieldID !== 1001
  )

  let preamble = ''
  preamble = 'Fitch Ratings'

  // Convert the date to Eastern Standard Time and
  // format it as dd Mon yyyy
  const formattedDate = FormatDate(
    getSFResearchItem.publishedDate,
    'dd MMM yyyy'
  )

  const docOrigin = getSFResearchItem.origin

  if (docOrigin) {
    preamble = preamble.concat(` - ${docOrigin}`)
  }
  if (formattedDate) {
    preamble = preamble.concat(` - ${formattedDate}`)
  }
  preamble = preamble.concat(': ')
  if (leadParagraph && leadParagraph.content) {
    let insertAt = 0
    if (leadParagraph.content.startsWith('<p>')) {
      insertAt = 3
    }
    leadParagraph.content =
      leadParagraph.content.slice(0, insertAt) +
      preamble +
      leadParagraph.content.slice(insertAt)
  }

  const parsePargraphCollapsibleProps = (header: string, paragraph: string) => {
    return {
      name: '',
      header,
      content: paragraph,
      subHeader: '',
      showHeader: true,
      readLessDisplay: updateDisplayLabel(displayLabelsCaseIS, 'Read Less'),
      readMoreDisplay: updateDisplayLabel(displayLabelsCaseIS, 'Read More')
    }
  }

  const insightBody = (
    <div className="RAC">
      {leadParagraph && (
        <Paragraph {...leadParagraph} nonUrlText={linkifyExclude} />
      )}

      <Paragraphs items={bodyParagraphs} nonUrlText={linkifyExclude} />

      <Paragraph
        content={getSFResearchItem.disclosure}
        readLessDisplay={updateDisplayLabel(displayLabelsCaseIS, 'Read Less')}
        readMoreDisplay={updateDisplayLabel(displayLabelsCaseIS, 'Read More')}
      />

      {_.isEmpty(_.get(getSFResearchItem, 'additionalDisclosures')) ? null : (
        <AdditionalDisclosures
          {...getSFResearchItem.additionalDisclosures}
          heading={updateDisplayLabel(
            displayLabelsCaseIS,
            getSFResearchItem.additionalDisclosures.heading
          )}
          endorsementPolicy={translateAdditionalDisclosuresSubSectionTitle(
            getSFResearchItem.additionalDisclosures.endorsementPolicy
          )}
          solicitationStatus={translateAdditionalDisclosuresSubSectionTitle(
            getSFResearchItem.additionalDisclosures.solicitationStatus
          )}
          slug={slug}
        />
      )}

      <footer>
        {_.isEmpty(_.get(getSFResearchItem, 'disclaimerCopyright')) ? (
          <>
            {_.isEmpty(_.get(getSFResearchItem, 'disclaimer')) ? null : (
              <ParagraphCollapsible
                {...parsePargraphCollapsibleProps(
                  updateDisplayLabel(displayLabelsCaseIS, 'DISCLAIMER'),
                  getSFResearchItem.disclaimer
                )}
              />
            )}
            {_.isEmpty(_.get(getSFResearchItem, 'copyright')) ? null : (
              <ParagraphCollapsible
                {...parsePargraphCollapsibleProps(
                  updateDisplayLabel(displayLabelsCaseIS, 'COPYRIGHT'),
                  getSFResearchItem.copyright
                )}
              />
            )}
          </>
        ) : (
          <>
            {_.isEmpty(
              _.get(getSFResearchItem, 'disclaimerCopyright')
            ) ? null : (
              <ParagraphCollapsible
                {...parsePargraphCollapsibleProps(
                  updateDisplayLabel(
                    displayLabelsCaseIS,
                    'DISCLAIMER & COPYRIGHT'
                  ),
                  getSFResearchItem.disclaimerCopyright
                )}
              />
            )}
          </>
        )}
      </footer>
    </div>
  )

  const paddingAndDirection = {
    paddingRight: reportUrl ? '3rem' : '10.7rem'
  }

  const authorOpenGraphTag = _.get(marketing, 'openGraphTags.author')

  const seoMarketing = {
    ...marketing,
    openGraphTags: {
      ...marketing.openGraphTags,
      author: _.isNull(authorOpenGraphTag) ? '' : authorOpenGraphTag
    }
  }

  const RatingHeaderProps = {
    dateTime: false,
    publishedDate: getSFResearchItem!.publishedDate,
    reportType: Subtitle[reportType],
    subtitle: Subtitle[reportType],
    title: getSFResearchItem!.title
  }

  return (
    <>
      <SEO
        title={getSFResearchItem!.title}
        description="article"
        keywords={[`market sector`, `rating`, researchTitle]}
        noIndex={getSFResearchItem!.noIndex}
        type="Research"
        {...seoMarketing}
      />
      <article>
        <div className="wrapper--1 content">
          <header className="article__header">
            <div className="page-layout page-layout--2__left-main-right">
              <div className="column__left" />
              <div className="column__main" style={paddingAndDirection}>
                <RatingHeader {...RatingHeaderProps} />
              </div>
              <div className="column__right" />
            </div>
          </header>
          <div className="page-layout page-layout--2__left-main-right">
            <div className="column__left ta--2">
              <Social
                copyText=""
                root={globalHistory.location.origin}
                title={researchTitle}
                url={globalHistory.location.pathname.slice(1)}
                report
              />
            </div>
            <div
              className="column__main"
              style={{ ...paddingAndDirection, marginBottom: '5rem' }}
            >
              {reportUrl != null ? (
                <ReportViewer {...getSFResearchItem} path={path} />
              ) : (
                insightBody
              )}
              <div className="section" />
            </div>
          </div>
        </div>
      </article>
    </>
  )
}

const hasRoute = (Component: ComponentType<InputProps>) => (props: Props) => {
  const { '*': path, uri } = props
  const slug = `${uri.slice(1)}/${path}`
  const linkifyExclude = _.get(
    useStaticQuery(staticQuery),
    'excludeLinks.excludeLinks',
    []
  )
  return (
    <div className="article-layout">
      <Main slug={slug} isRAC isInsights headerInverted={false}>
        <Component linkifyExclude={linkifyExclude} path={slug} slug={slug} />
      </Main>
    </div>
  )
}

export default compose([hasRoute, hasRuntimeQuery, hasRAC, hasLoading])(wrapper)
